export const LIGHT_SPEED_RESOURCE_TYPE = 'code';
export const LIGHT_SPEED_SCOPE = 'items orders-api financial-api';
export const LOYVERSE_SCOPE = 'ITEMS_READ RECEIPTS_READ STORES_READ';
export const ORACLE_NETSUITE_SCOPE = 'rest_webservices';
export const XERO_SCOPE =
  'openid profile offline_access accounting.transactions accounting.settings accounting.contacts';
export const XERO_RESOURCE_TYPE = 'code';
export const ORACLE_NETSUITE_RESOURCE_TYPE = 'code';
export const MYOB_RESOURCE_TYPE = 'code';
export const ZOHO_RESOURCE_TYPE = 'code';
export const ZOHO_SCOPE =
  'ZohoBooks.accountants.Create ZohoBooks.accountants.UPDATE ZohoBooks.accountants.READ ZohoBooks.accountants.DELETE ZohoBooks.bills.Create ZohoBooks.bills.UPDATE ZohoBooks.bills.READ ZohoBooks.bills.DELETE ZohoBooks.contacts.Create ZohoBooks.contacts.UPDATE ZohoBooks.contacts.READ ZohoBooks.contacts.DELETE ZohoBooks.settings.Create ZohoBooks.settings.UPDATE ZohoBooks.settings.READ ZohoBooks.settings.DELETE';
export const MYOB_SCOPE = 'CompanyFile';
export const QUICKBOOKS_RESOURCE_TYPE = 'code';
export const QUICKBOOKS_SCOPE = 'com.intuit.quickbooks.accounting';
export const QUICKBOOKS_STATE = 'PlaygroundAuth';
export const ZOHO_ACCESS_TYPE_SCOPE = 'offline';
export const PROVIDER_AUTH_STATE_KEY = 'provider-auth-state';
export const SQUARE_SCOPE = 'ORDERS_READ ITEMS_READ MERCHANT_PROFILE_READ PAYMENTS_READ';
export const POSTER_RESPONSE_TYPE = 'code';
export const ZID_RESPONSE_TYPE = 'code';
