<div class="supy-sales-transactions">
  <div class="supy-sales-transactions__header">
    <div class="supy-sales-transactions__title">
      <h5 i18n="@@integrations.reviewData">Review Data</h5>
      <supy-icon [supyTooltip]="statusesTooltip" name="info" size="small" position="right"></supy-icon>
    </div>

    <div class="supy-sales-transactions__actions">
      <supy-button
        *ngIf="grid?.getSelectedRows()?.length > 0"
        name="delete-selected"
        color="error"
        (buttonClick)="deleteTransactions()"
      >
        <supy-icon name="trash"></supy-icon>
        <span i18n="@@deleteSelected">Delete Selected</span>
      </supy-button>

      <supy-button *ngIf="actionTitle" name="action" color="primary" (buttonClick)="action.emit()">
        {{ actionTitle }}
      </supy-button>

      <supy-button
        *ngIf="isExportAllowed"
        color="secondary"
        name="export-list"
        (buttonClick)="export.emit()"
        i18n="@@common.actions.export"
        >Export</supy-button
      >
    </div>
  </div>

  <div class="supy-sales-transactions__actions">
    <supy-tabview-select
      [items]="statuses()"
      [value]="status"
      (selectedChanged)="onStatusChange($event)"
    ></supy-tabview-select>

    <div class="supy-sales-transactions__banner" *ngIf="bannerMessage">
      <supy-icon name="info" color="warn" size="small"></supy-icon>
      <span class="supy-sales-transactions__banner--text">{{ bannerMessage }}</span>
    </div>

    <supy-input
      [style.width]="'15rem'"
      class="supy-sales-transactions__search"
      [latency]="300"
      name="search"
      placeholder="Search POS Items"
      suffix="search"
      (valueChange)="search.emit($event)"
    ></supy-input>
  </div>

  <div class="supy-sales-transactions__grid-wrapper">
    <supy-grid
      class="supy-sales-transactions__grid"
      [data]="gridTransactions()"
      [selectedRows]="gridSelectedRows"
      [emptyGridTemplate]="emptyGridTemplate"
      [rowSelection]="gridSelectionMode.multiple"
      [paginationMode]="paginationMode"
      [page]="requestMetadata.page"
      [perPage]="requestMetadata.limit"
      [minRowHeight]="rowHeight"
      [rowHeightFlexible]="false"
      [prevPageDisabled]="requestMetadata.page === 0"
      [nextPageDisabled]="responseMetadata.count < requestMetadata.limit"
      (pageChange)="onPageChange($event)"
      (rowSelectionChanging)="onSelectionChange($event)"
      primaryKey="idToOrderId"
    >
      <supy-grid-column header="Sales Date" field="businessDay" width="10%">
        <ng-template supyGridCell let-value>
          {{ value | date: 'dd MMM yyyy' }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column
        header="POS Item Code"
        field="posItem.code"
        [width]="isSalesTypeShown || isOrderShown ? '7%' : '15%'"
      >
        <ng-template supyGridCell let-value>
          <div class="truncate">
            <span [supyTooltip]="posItemCodeTooltip" [hideDelay]="2000"> {{ value ?? '' }}</span>
          </div>

          <supy-tooltip #posItemCodeTooltip>
            <div class="truncate">
              <span>{{ value }}</span>
            </div>
          </supy-tooltip>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="POS Item Name" width="14%">
        <ng-template supyGridCell let-rowData="rowData">
          <div class="supy-sales-transactions__grid-cell truncate">
            <supy-icon
              [supyTooltip]="parentItemTooltip"
              [hideDelay]="2000"
              *ngIf="rowData.parentItem"
              name="add-item"
              color="info"
              size="small"
            ></supy-icon>
            <div class="truncate">
              <span [class.parentless]="rowData.parentItem && !rowData.hasParent" [title]="rowData.posItem.name">
                {{ rowData.posItem.name }}</span
              >
            </div>

            <supy-icon
              *ngIf="rowData.posItem.nameModified"
              [supyTooltip]="modifiedNameTooltip"
              name="info"
              size="small"
              color="error"
              position="right"
            ></supy-icon>

            <supy-tooltip #modifiedNameTooltip>
              <div>
                <span i18n="@@integrations.itemNameTooltip" class="supy-sales-transactions__tooltip"
                  >The item name has been modified in the POS system.</span
                >
              </div>
            </supy-tooltip>

            <supy-tooltip #parentItemTooltip>
              <div>
                <span class="supy-sales-transactions__tooltip"
                  >This modifier is related to the next pos item: {{ rowData.parentItem?.code }}</span
                >
              </div>
              <div *ngIf="rowData.parentItem && !rowData.hasParent">
                <span class="supy-sales-transactions__tooltip"
                  >The parent item can be found on the: (previous page, different status tab) or it was removed</span
                >
              </div>
            </supy-tooltip>
          </div>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Quantity Sold" field="quantity" width="8%">
        <ng-template supyGridCell let-value>
          {{ value ?? 0 | supyPrecision: 2 }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Total Discount" field="totalSales.discount" width="8%">
        <ng-template supyGridCell let-value>
          {{ value ?? 0 | supyPrecision: currencyPrecision }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Total Sales Incl. Tax" field="totalSales.inclTax" width="8%">
        <ng-template supyGridCell let-value>
          {{ value ?? 0 | supyPrecision: currencyPrecision }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Total Sales Excl. Tax" field="totalSales.exclTax" width="8%">
        <ng-template supyGridCell let-value>
          {{ value ?? 0 | supyPrecision: currencyPrecision }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column
        *ngIf="isRecipeShown"
        header="Supy Recipe"
        [width]="isSalesTypeShown && isOrderShown ? '10%' : '18%'"
      >
        <ng-template supyGridCell let-rowData="rowData">
          <supy-autocomplete
            *ngIf="isRecipeSelectionShown(rowData)"
            placeholder="Search Recipes"
            suffix="search"
            [id]="rowData?.id"
            [withValidatorFn]="false"
            [prefix]="getRecipeIcon(rowData)"
            [debounceTime]="300"
            [clearable]="true"
            [focusOnInit]="false"
            [options]="currentRecipeId() === rowData.id ? recipesList() : []"
            [value]="rowData.linkedRecipe"
            [customOptionsTemplate]="customOptionsTemplate"
            [displayValueFn]="recipeDisplayValueFn"
            [noMatchOption]="noMatchOption"
            [isLoading]="areRecipesLoading && currentRecipeId() === rowData.id"
            [fetchDone]="getRecipesFetchDone(rowData)"
            (valueChange)="onRecipeSelected($event, rowData.posItem.code)"
            (searchValueChange)="onRecipesSearchValueChange($event, rowData)"
            (cleared)="onRecipeCleared(rowData.id, rowData.posItem.code)"
            (closed)="resetRecipes()"
          ></supy-autocomplete>

          <ng-template #customOptionsTemplate let-option="option">
            <span>{{ option.name.en || option.name.ar }}</span>
          </ng-template>

          <ng-template #noMatchOption>
            <small *ngIf="canCreateRecipe()">
              No recipe?
              <a routerLink="/inventory/recipes/create/details" target="_blank"> <small>Create a new</small> </a>
            </small>
          </ng-template>

          <a
            *ngIf="!isRecipeSelectionShown(rowData)"
            class="supy-sales-transactions__grid-link truncate"
            routerLink="/repository/recipes/{{ rowData.linkedRecipe?.id }}/details"
            target="_blank"
            ><span class="truncate"> {{ rowData.linkedRecipe?.name.en }}</span></a
          >
        </ng-template>
      </supy-grid-column>

      <supy-grid-column *ngIf="!isRecipeShown" width="18%">
        <ng-template supyGridCell></ng-template>
      </supy-grid-column>

      <supy-grid-column *ngIf="isOrderShown" header="Order ID" field="salesOrderId" width="8%">
        <ng-template supyGridCell let-value="value">
          <small class="truncate" [title]="value">{{ value }}</small>
        </ng-template>
      </supy-grid-column>

      <supy-grid-column *ngIf="isSalesTypeShown" header="Sales Type" field="salesType" width="8%">
        <ng-template supyGridCell let-value="value">
          <small class="truncate" [title]="value?.providerCode" *ngIf="value?.retailerSalesTypeName">{{
            value?.retailerSalesTypeName ?? 'Unmapped'
          }}</small>
          <strong [title]="value?.providerCode" *ngIf="!value?.retailerSalesTypeName">Unmapped</strong>
        </ng-template>
      </supy-grid-column>

      <supy-grid-column width="3%">
        <ng-template supyGridCell let-rowData="rowData">
          <supy-button
            *ngIf="canDeleteSalesImport()"
            (buttonClick)="deleteTransaction(rowData.id)"
            variant="icon"
            color="clear"
            title="Delete"
          >
            <supy-icon name="trash" color="error"></supy-icon>
          </supy-button>
        </ng-template>
      </supy-grid-column>

      <supy-grid-column width="3%">
        <ng-template supyGridCell let-rowData="rowData">
          <supy-button
            *ngIf="isPossibleToIgnore(rowData)"
            (buttonClick)="onIgnore(rowData)"
            variant="icon"
            color="clear"
          >
            <supy-icon name="eye-slash"></supy-icon>
          </supy-button>

          <supy-button
            *ngIf="isPossibleToUnignore(rowData)"
            (buttonClick)="onUnignore(rowData)"
            variant="icon"
            color="clear"
          >
            <supy-icon name="eye-visible"></supy-icon>
          </supy-button>
        </ng-template>
      </supy-grid-column>
    </supy-grid>
    <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>
  </div>
</div>

<ng-template #emptyGridTemplate>
  <div class="supy-sales-transactions__empty">
    <supy-icon name="empty" size="extra-huge"></supy-icon>
    <span class="supy-sales-transactions__empty--title">
      There are no uploads at the moment to display review data here.
    </span>
  </div>
</ng-template>

<supy-tooltip #statusesTooltip>
  <div>
    <small><strong>Unmapped</strong> - List of all the PLUs that are not mapped to a recipe</small>
  </div>
  <div>
    <small><strong>Unlinked</strong> - List of all the PLUs that are mapped to a recipe with no cost center</small>
  </div>
  <div>
    <small><strong>Void/Canceled</strong> - List of all cancelled orders</small>
  </div>
  <div>
    <small><strong>Synced</strong> - List of all the submitted PLUs</small>
  </div>
  <div class="supy-sales-transactions__legend">
    <supy-icon name="add-item" color="info" size="small"></supy-icon>
    <small> - Modifier/Add-on</small>
  </div>
</supy-tooltip>
